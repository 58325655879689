import Vue from 'vue';
const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
import dayjs from 'dayjs';
Vue.mixin({
  data() {
    return {
      ticketStatus: ['big', 'small', 'odd', 'even', 'draw_big_small', 'draw_even_odd', 'even11', 'odd11'],
      mapTicketInfo: {
        big: 'Lớn',
        small: 'Nhỏ',
        even: 'Chẵn',
        odd: 'Lẻ',
        draw_big_small: 'Hoà (L-N)',
        draw_even_odd: 'Hoà (C-L)',
        even11: 'Chẵn (11-12',
        odd11: 'Lẻ (11-12)'
      }
    };
  },
  updated() {},
  methods: {
    formatNumberFromString(string) {
      const number = string.split('.').join('');
      if (isNaN(parseFloat(number))) {
        return null;
      }
      return parseFloat(number);
    },
    formatPrice(value, currency = 'đ') {
      if (value !== undefined && value !== null) {
        let val = (value / 1).toFixed(0).replace('.', ',');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + currency;
      }
      return 0 + currency;
    },
    removeFalsyKey(obj) {
      let newObj = {};
      Object.keys(obj).forEach((prop) => {
        if (obj[prop]) {
          newObj[prop] = obj[prop];
        }
      });
      return newObj;
    },
    formatDate(date, isOnlyDate) {
      var dateStr = `${date.getFullYear()}-${date.getMonth() < 9 ? 0 : ''}${date.getMonth() + 1}-${
        date.getDate() < 10 ? 0 : ''
      }${date.getDate()}`;
      var time = `${date.getHours() < 10 ? 0 : ''}${date.getHours()}:${
        date.getMinutes() < 10 ? 0 : ''
      }${date.getMinutes()}:${date.getSeconds() < 10 ? 0 : ''}${date.getSeconds()}`;
      if (isOnlyDate) {
        return `${dateStr}`;
      }
      return `${dateStr} ${time}`;
    },
    getDayName(day) {
      return DAYS[day];
    },
    getMetadata(schedule, splitter = ',\n') {
      let scheduleArr = JSON.parse(schedule);
      if (scheduleArr.length > 0) return scheduleArr.map((s) => this.getDayName(s)).join(splitter);
      else
        return Object.entries(scheduleArr)
          .map((s) => s.join(': '))
          .join(splitter);
    },
    mixNumber(number) {
      if (typeof number === 'number') {
        return `${number < 10 ? '0' : ''}${number}`;
      }
      return number;
    },
    formatTime(time, format = 'D/M/YYYY') {
      if (time !== undefined && time !== null) {
        return dayjs(time).locale('vi').format(format);
      }
      return '';
    }
  }
});
